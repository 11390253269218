export * as ACCOUNT_API_ERROR_CODES from "./account/api/errorCodes";
export * as BILLING from "./billing";
export * as BREAKPOINTS from "./breakpoints";
export * as CLUBS from "./clubs";
export * as CONTENT_TYPES from "./contentTypes";
export * as COOKIES from "./cookies";
export * as COUNTRIES from "./countries";
export * as DATETIME from "./dateTime";
export * as GROUP_FITNESS from "./groupFitness";
export * as LOCAL_STORAGE_KEYS from "./localStorageKeys";
export * as MEMBERSHIP from "./membership";
export * as MESSAGES from "./messages";
export * as PLANS from "./plans";
export * as PROSPECT from "./prospect";
export * as REGEX from "./regex";
export * as REGIONS from "./regions";
export * as ROUTES from "./routes";
export * as SESSION_STORAGE_KEYS from "./sessionStorageKeys";
export * as TIMINGS from "./timings";

export const ASSETS_CDN_HOST = "https://assets.cdn-equinox.com";
export const FONT_FILE_VERSION = 3;
export const IMAGE_ASSETS_CDN_PATH = ASSETS_CDN_HOST + "/images";

export const CLASS_SCHEDULE_DOWNLOAD_PATH = "/groupfitness/classes/download";

export const MOBILE_APP_WEB_VIEW_KEY = "mobileApp";
export const MOBILE_APP_WEB_VIEW_VALUE = "equinox-plus-app";

export const NEWSLETTER_DECLINE_ANSWER_ID = 129;
export const NEWSLETTER_SUBSCRIBE_ANSWER_ID = 130;

export const CLUB_STATUSES = {
  COMING_SOON: "Coming Soon",
  LEAD_INEPT: "LeadInept",
  OPEN: "Open",
  PRESALE: "Presale",
};

export const CLUB_TYPES = {
  E: "E",
  REGULAR: "Regular",
  SPORTS_CLUB: "SportsClub",
};

export const CONTACT_EMAIL = "concierge@equinox.com";
export const CONTACT_PHONE = "855-202-7255";

export const COPYRIGHT_NOTICE = `Copyright © ${new Date().getFullYear()} Equinox Holdings, Inc.`;

export const EQX_PLUS_APP_STORE_URL =
  "https://apps.apple.com/us/app/equinox/id318815572";
export const EQX_PLUS_GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.equinoxfitness.equinox";
