export const ALL_ACCESS_FITNESS_2 = {
  id: 2221,
  name: "All Access Fitness 2",
};

export const BOSTON_ACCESS = {
  id: 2,
  name: "Boston Access",
};

export const CALIFORNIA_ACCESS = {
  id: 12,
  name: "California Access",
};

export const CANADA_ACCESS = {
  id: 14,
  name: "Canada Access",
};

export const CHICAGO_ACCESS = {
  id: 3,
  name: "Chicago Access",
};

export const CORP_ALL_ACCESS_FITNESS_MONTHLY_2 = {
  id: 2224,
  name: "Corp All Access Fitness Monthly 2",
};

export const CORP_BOSTON_ACCESS = {
  id: 1054,
  name: "Corp Boston Access",
};

export const CORP_CANADA_ACCESS = {
  id: 1112,
  name: "Corp Canada Access",
};

export const CORP_CHICAGO_ACCESS = {
  id: 1062,
  name: "Corp Chicago Access",
};

export const CORP_DALLAS_ACCESS = {
  id: 1106,
  name: "Corp Dallas Access",
};

export const CORP_DESTINATION_ACCESS_MONTHLY_2 = {
  id: 2417,
  name: "Corp Destination Access Monthly 2",
};

export const CORP_DESTINATION_ACCESS_MONTHLY_HY = {
  id: 2467,
  name: "Corp Destination Access Monthly HY",
};

export const CORP_DESTINATION_EXECUTIVE_ACCESS = {
  id: 1066,
  name: "Corp Destination Executive Access",
};

export const CORP_DESTINATION_UK_ACCESS_MONTHLY_2 = {
  id: 2469,
  name: "Corp Destination UK Access Monthly 2",
};

export const CORP_DESTINATION_UK_ACCESS_MONTHLY_HY = {
  id: 2470,
  name: "Corp Destination UK Access Monthly HY",
};

export const CORP_FLORIDA_ACCESS = {
  id: 1070,
  name: "Corp Florida Access",
};

export const CORP_METRO_DC_ACCESS = {
  id: 1072,
  name: "Corp Metro D.C. Access",
};

export const CORP_METRO_NY_ACCESS = {
  id: 1100,
  name: "Corp Metro NY Access",
};

export const CORP_NORTHERN_CALIFORNIA_ACCESS = {
  id: 1102,
  name: "Corp Northern California Access",
};

export const CORP_ORANGE_COUNTY_ACCESS = {
  id: 1104,
  name: "Corp Orange County Access",
};

export const CORP_SOUTHERN_CALIFORNIA_ACCESS = {
  id: 1726,
  name: "Corp Southern California Access",
};

export const DALLAS_ACCESS = {
  id: 4,
  name: "Dallas Access",
};

export const DESTINATION_ACCESS = {
  id: 5,
  name: "Destination Access",
};

export const DESTINATION_ACCESS_2 = {
  id: 2396,
  name: "Destination Access 2",
};

export const DESTINATION_ACCESS_3_MONTHLY = {
  id: 2735,
  name: "Destination Access 3 Monthly",
};

export const DESTINATION_ACCESS_CCY_MONTHLY = {
  id: 2737,
  name: "Destination Access CCY Monthly",
};

export const DESTINATION_ACCESS_HY = {
  id: 2397,
  name: "Destination Access HY",
};

export const DESTINATION_UK_ACCESS = {
  id: 978,
  name: "Destination UK Access",
};

export const E_ACCESS = {
  id: 7,
  name: "E Access",
};

export const EAST_VALLEY_DOWNTOWN_LA_ACCESS = {
  id: 16,
  name: "East Valley Downtown LA Access",
};

export const EQUINOX_PLUS_ACCESS = {
  id: 2056,
  name: "Equinox+ Access",
};

export const FLORIDA_ACCESS = {
  id: 8,
  name: "Florida Access",
};

export const HEALTH_WEST_ACCESS = {
  id: 98,
  name: "Health West Access",
};

export const METRO_DC_ACCESS = {
  id: 9,
  name: "Metro D.C. Access",
};

export const METRO_NY_ACCESS = {
  id: 10,
  name: "Metro NY Access",
};

export const NORTHERN_CALIFORNIA_ACCESS = {
  id: 11,
  name: "Northern California Access",
};

export const ORANGE_COUNTY_ACCESS = {
  id: 13,
  name: "Orange County Access",
};

export const SELECT_ACCESS = {
  id: 15,
  name: "Select Access",
};

export const SOUTHERN_CALIFORNIA_ACCESS = {
  id: 1724,
  name: "Southern California Access",
};

const PLANS = [
  ALL_ACCESS_FITNESS_2,
  BOSTON_ACCESS,
  CALIFORNIA_ACCESS,
  CANADA_ACCESS,
  CHICAGO_ACCESS,
  CORP_ALL_ACCESS_FITNESS_MONTHLY_2,
  CORP_BOSTON_ACCESS,
  CORP_CANADA_ACCESS,
  CORP_CHICAGO_ACCESS,
  CORP_DALLAS_ACCESS,
  CORP_DESTINATION_ACCESS_MONTHLY_2,
  CORP_DESTINATION_ACCESS_MONTHLY_HY,
  CORP_DESTINATION_EXECUTIVE_ACCESS,
  CORP_DESTINATION_UK_ACCESS_MONTHLY_2,
  CORP_DESTINATION_UK_ACCESS_MONTHLY_HY,
  CORP_FLORIDA_ACCESS,
  CORP_METRO_DC_ACCESS,
  CORP_METRO_NY_ACCESS,
  CORP_NORTHERN_CALIFORNIA_ACCESS,
  CORP_ORANGE_COUNTY_ACCESS,
  CORP_SOUTHERN_CALIFORNIA_ACCESS,
  DALLAS_ACCESS,
  DESTINATION_ACCESS,
  DESTINATION_ACCESS_2,
  DESTINATION_ACCESS_3_MONTHLY,
  DESTINATION_ACCESS_CCY_MONTHLY,
  DESTINATION_ACCESS_HY,
  DESTINATION_UK_ACCESS,
  E_ACCESS,
  EAST_VALLEY_DOWNTOWN_LA_ACCESS,
  EQUINOX_PLUS_ACCESS,
  FLORIDA_ACCESS,
  HEALTH_WEST_ACCESS,
  METRO_DC_ACCESS,
  METRO_NY_ACCESS,
  NORTHERN_CALIFORNIA_ACCESS,
  ORANGE_COUNTY_ACCESS,
  SELECT_ACCESS,
  SOUTHERN_CALIFORNIA_ACCESS,
];

const byId = {};

PLANS.forEach((plan) => {
  byId[plan.id] = plan;
});

export { byId };

export default PLANS;
