// this file is generated by running scripts/groupFitness.js

export const ATHLETIC_TRAINING = {
  description:
    "Equinox calorie-torching HIIT workouts will leave you breathless and feeling the burn in every muscle.",
  id: 5,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5etd2gDXxyQaoKjqIuiGMK/0f5d6ecd9aa9378b54a48f49c637425b/athletic-training.webp",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5etd2gDXxyQaoKjqIuiGMK/0f5d6ecd9aa9378b54a48f49c637425b/athletic-training.webp",
  slug: "ATHLETIC_TRAINING",
  title: "Athletic Training",
};

export const BARRE = {
  description:
    "We’ve married the rigor and tradition born out of ballet with the precise technique of high-performance athletes to create the best Barre experience for you.",
  id: 4,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5P9TGd0dhAGEcf2fjKqrXC/8bfab85fe9e202600ec64e894777cee7/Barre.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5P9TGd0dhAGEcf2fjKqrXC/8bfab85fe9e202600ec64e894777cee7/Barre.jpg",
  slug: "BARRE",
  title: "Barre",
};

export const BOXING_AND_KICKBOXING = {
  description:
    "Build a true fighting machine with boxing and kickboxing classes. We honor the fighting traditions by rigorously adapting them into hard-hitting martial arts classes that will bring out your inner warrior.",
  id: 2,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/22KRjzRzp7ntmthG5FiDPU/c705930e32789e27d70ab6a60fee08ed/Boxing.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/22KRjzRzp7ntmthG5FiDPU/c705930e32789e27d70ab6a60fee08ed/Boxing.jpg",
  slug: "BOXING_AND_KICKBOXING",
  title: "Boxing and Kickboxing",
};

export const CYCLING = {
  description:
    "Cardio training meets inspired playlists, with a variety of drills, intervals, and team challenges at our signature cycling classes. Climb, sprint, and let the energy move you.",
  id: 6,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6nHyHWAs8Yq5idH3u4i8XU/f7576bd471a53059ab5b3f99b8e838ca/cycling2.webp",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6nHyHWAs8Yq5idH3u4i8XU/f7576bd471a53059ab5b3f99b8e838ca/cycling2.webp",
  slug: "CYCLING",
  title: "Cycling",
};

export const DANCE = {
  description:
    "Get into the groove with our high-energy dance workouts, ranging in skill-level from easy-to-follow Zumba to Broadway-level choreography. With our dance fitness instructors, you’ll have fun while getting your sweat on.",
  id: 106,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/7ieYRcyj9eZwbemwSP3OMO/35658c98930b94991c482bdb4532a4c7/Dance__high_res_.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/7ieYRcyj9eZwbemwSP3OMO/35658c98930b94991c482bdb4532a4c7/Dance__high_res_.jpg",
  slug: "DANCE",
  title: "Dance",
};

export const OUTDOOR_FITNESS = {
  description:
    "Outdoor fitness category for pay per class\n" +
    "currently using this for the Pilates Group Training",
  id: 214,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/3PzDZPBu9xVb7ExJWTEcfc/178acea3aa6ba6017ee02f13ef385f8f/outdoor_fitness.png",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6lT3MKBBa102uGoHWEvF8l/4740bd817f44d092b9de9ab18fb99520/outdoor_fitness.png",
  slug: "OUTDOOR_FITNESS",
  title: "Outdoor Fitness",
};

export const PILATES = {
  description:
    "No muscle is ever over or under-trained in our challenging Mat Pilates workouts. We offer classical, modern, and innovative fusion Pilates classes that guaranteed to strengthen your abs, burn fat, and sculpt your body.",
  id: 105,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/4wwlGMmewFFvSF30A92eh7/cdc8f30f2fe88b63658fa6cc576a6039/Pilates.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/4wwlGMmewFFvSF30A92eh7/cdc8f30f2fe88b63658fa6cc576a6039/Pilates.jpg",
  slug: "PILATES",
  title: "Pilates",
};

export const POOL = {
  description:
    "Pool workouts target every muscle group in a single class. Low-impact yet high-intensity, our swimming classes combine classic strokes with innovative training tools, making for one-of-a-kind, full-body workouts.",
  id: 203,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6bPtIBZrl68lacstLmKyTx/b6cf6205423c4aaa425cf77f7b36e21a/Pool.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6bPtIBZrl68lacstLmKyTx/b6cf6205423c4aaa425cf77f7b36e21a/Pool.jpg",
  slug: "POOL",
  title: "Pool",
};

export const RUNNING = {
  description:
    "Blaze new trails with Precision Run from Equinox. This precision running workout is redefining our indoor treadmill training through a patterned flow.",
  id: 202,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/392LGRouJnZqkVu54vkOcs/3d2e2f74c0fa2232b3a7fa3f695f01fc/Running.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/392LGRouJnZqkVu54vkOcs/3d2e2f74c0fa2232b3a7fa3f695f01fc/Running.jpg",
  slug: "RUNNING",
  title: "Running",
};

export const SCULPT = {
  description:
    "If long and lean body is your goal, our challenging conditioning classes will push you to sculpt, tone, and burn toward your transformation. You will build strength, endurance and shape every muscle in your body.",
  id: 3,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6KRSI1wmGzOrIITNutZPMK/ed855f95677eae00e84148b59e70a008/Equinox_ClubPage_Classes_LONG___LEAN_DT_3200x2133_____6.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/6ojJXTiDDR31EMIuzg1DFx/1ddab21e35aa7f291f915d5521ad38e6/Equinox_ClubPage_Classes_LONG___LEAN_TB_2100X2800_____6.jpg",
  slug: "SCULPT",
  title: "Sculpt",
};

export const STRENGTH = {
  description: "Strength",
  id: 213,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5etd2gDXxyQaoKjqIuiGMK/0f5d6ecd9aa9378b54a48f49c637425b/athletic-training.webp",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/5etd2gDXxyQaoKjqIuiGMK/0f5d6ecd9aa9378b54a48f49c637425b/athletic-training.webp",
  slug: "STRENGTH",
  title: "Strength",
};

export const STRETCH_AND_RECOVERY = {
  description:
    "Active recovery is a vital part of a high-performance lifestyle. Reboot your body post-performance with stretching workouts focusing on self-massage and controlled breathing techniques.",
  id: 210,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/7x26RPghZBHCuRKDaftqy7/c1534335066272365529824155377a22/Stretch_and_Recovery.png",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/7x26RPghZBHCuRKDaftqy7/c1534335066272365529824155377a22/Stretch_and_Recovery.png",
  slug: "STRETCH_AND_RECOVERY",
  title: "Stretch and Recovery",
};

export const TB12 = {
  description: "TB12",
  id: 215,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/2ziqnSe7JNvCONoXz6A570/eae7bda730ef40fce6652552b604ebc3/tb12.png",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/7c53lZBW9ab4E5S0qUi6T9/663d8438c87350fcefa5c31801f92fe4/Media_collection.postman_collection.jpeg",
  slug: "TB12",
  title: "TB12",
};

export const YOGA = {
  description:
    "Whether you’re looking for a challenging experience or a beginner yoga class near you, Equinox members enjoy access to a wide array of yoga classes in our state-of-the-art studios.",
  id: 104,
  imageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/4BUMoLlcb1Tbbhn9ztQEEO/62e9d109c1b4d8b7fe10f2100554ee68/Yoga.jpg",
  mobileImageUrl:
    "//images.ctfassets.net/drib7o8rcbyf/4BUMoLlcb1Tbbhn9ztQEEO/62e9d109c1b4d8b7fe10f2100554ee68/Yoga.jpg",
  slug: "YOGA",
  title: "Yoga",
};

const CLASS_CATEGORIES = [
  ATHLETIC_TRAINING,
  BARRE,
  BOXING_AND_KICKBOXING,
  CYCLING,
  DANCE,
  OUTDOOR_FITNESS,
  PILATES,
  POOL,
  RUNNING,
  SCULPT,
  STRENGTH,
  STRETCH_AND_RECOVERY,
  TB12,
  YOGA,
];

const byId = {};

CLASS_CATEGORIES.forEach((classCategory) => {
  byId[classCategory.id] = classCategory;
});

export { byId };

export default CLASS_CATEGORIES;
