export const PAYMENT_JOIN_PRODUCT_TOKEN = {
  name: "joinToken",
};

export const NEARBY_FACILITES = {
  expiration: 86400, // in seconds = 1 day
  name: "EqxWeb.FacilityIDs",
};

export const USER_LOCATION = {
  expiration: 1800, // in seconds = 30 minutes
  name: "EqxWeb.Location",
};

export const USER_SESSION_ACCESS_TOKEN = {
  name: "EqxWeb.bavToken",
};

export const USER_INFO = {
  name: "userInfo",
};

export const JOIN_TODAY = {
  name: "joinToday",
};

export const CONFIRMATION_TOKEN = {
  name: "confirmationToken",
};

export const SELECTED_CLUB_ID = {
  name: "selectedClubId",
};
